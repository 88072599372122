import React from 'react'
import './App.scss'
import { Routing } from './components/routing/Routing'


const App = () => {
    return <Routing />
}

export default App
