import React, { FC, Suspense } from 'react'
import { mobilecheck } from '../../modules/mobilecheck'

const MobileRouting = React.lazy(() => import('./MobileRouting'))
const DesktopRouting = React.lazy(() => import('./DesktopRouting'))


const Loading: FC = () => <h1 style={{ textAlign: 'center', verticalAlign: 'center', lineHeight: '100vh', color: 'darkgray' }}>Laden ....</h1>

export const Routing: FC = () => {
    if (mobilecheck()) {
        return <Suspense fallback={<Loading/>}><MobileRouting/></Suspense>
    }
    return <Suspense fallback={<Loading/>}><DesktopRouting/></Suspense>
}
